export default class config {

  public static WPGRAPHQL_URL: string = "https://wordpress.savetherepublic.us/graphql"
  public static SITE_META_TITLE: string = "Save The Republic"
  public static SITE_META_AUTHOR: string = "Save The Republic"
  public static SITE_META_DESCRIPTION: string = "Let's save the republic one fact at a time."
  public static SITE_DOMAIN: string = "savetherepublic.us"
  public static SITE_META_URL: string = "https://savetherepublic.us"
  public static SITE_META_TWITTER: string = ""
  public static SITE_ICON: string = "savetherepublic-favicon.png"
  public static MANIFEST_NAME: string = "Save The Republic"
  public static MANIFEST_SHORT_NAME: string = "Save The Republic"
  public static MANIFEST_THEME_COLOR: string = "#ffffff"
  public static MANIFEST_ICON: string = "static/savetherepublic-favicon.png"
  public static BUCKET_NAME: string = "savetherepublicwebsite"
  public static GOOGLE_ANALYTICS_TRACKING_ID: string = "UA-177329305-1"
  public static GOOGLE_SEARCH_ENGINE_ID: string = "bf179161e661e132b"
  public static AUTHOR_NAME: string = "Save The Republic"
  public static FACEBOOK_PAGE_NAME: string = "savetherepublicrightnow"
  public static TWITTER_USERNAME: string = "savewithfacts"
  public static INSTAGRAM_USERNAME: string = "savetherepublicnow"
  public static SHARP_IGNORE_GLOBAL_LIBVIPS: string = "true"
  public static ADD_THIS_ID: string = "ra-60d277786c112659"
  public static LOGROCKET_APP_ID: string = "r4uqdw/save-the-republic"

}