import config from "./config"

// normalize CSS across browsers
import "./src/normalize.css"

// custom typefaces
import "typeface-montserrat"
import "typeface-merriweather"

// Glyphs
import "@fortawesome/fontawesome-free/js/all.js"

// Wordpress CSS styles
import "./src/css/blocks.editor.build.css"
import "./src/css/ultimate-blocks.style.build.css"

// import "./src/css/font-awesome.css"
// import "./src/css/font-awesome-free.css"
import "./src/css/i2-pro-icons-styles.css"

// custom CSS styles
import "./src/style.css"
import "bulma-social/css/single/facebook/facebook.min.css"
import "bulma-social/css/single/twitter/twitter.min.css"
import "bulma-social/css/single/instagram/instagram.min.css"

import LogRocket from "logrocket"

export const onInitialClientRender = () => {
  if (config.LOGROCKET_APP_ID) {
    LogRocket.init(config.LOGROCKET_APP_ID, {
      network: {
        requestSanitizer: request => {

          // disable LogRocket when in dev mode
          if (typeof document !== undefined) {
            if(document.cookie.match(/^(.*;)?\s*isDevModeEnabled\s*=\s*[^;]+(.*)?$/)) {
              return null
            }
          }

          // otherwise log the request normally
          return request
        },
      },
    })
  }
}
